<script context="module" lang="ts">
	import AppMenuItem from './AppMenuItem.svelte';
	import icoHome from 'iconoir/icons/home-alt-slim-horiz.svg';
	import icoPrograms from 'iconoir/icons/page.svg';
	import icoDash from 'iconoir/icons/report-columns.svg';
	import icoEvents from 'iconoir/icons/calendar.svg';
	import icoMore from 'iconoir/icons/more-horiz-circle.svg';
</script>

<nav
	class="fixed bottom-0 left-0 right-0 rounded-t-xl bg-neutral-50 px-4 py-2 drop-shadow-[0_-8px_5px_rgba(0,0,0,0.08)] dark:bg-neutral-800"
>
	<div class="m-auto flex max-w-md justify-between" role="menubar">
		<AppMenuItem itemIcon={icoDash} itemName="Portal" itemLink="/dash" />
		<AppMenuItem itemIcon={icoPrograms} itemName="Programs" itemLink="/programs" />
		<AppMenuItem itemIcon={icoHome} itemName="Home" itemLink="/" />
		<AppMenuItem itemIcon={icoEvents} itemName="Events" itemLink="/events" />
		<AppMenuItem itemIcon={icoMore} itemName="Resources" itemLink="/more" />
	</div>
</nav>
