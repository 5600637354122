<script lang="ts">
	export let itemName = 'Null';
	export let itemLink = '#';
	export let itemDropdown = false;
	import icoMore from 'iconoir/icons/nav-arrow-down.svg';
</script>

<div role="menuitem" class="text-center">
	<style>
		.underliner {
			background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
			background-size: 0 2px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			transition: background-size 0.3s ease-in-out;
		}

		.underliner:hover {
			background-size: 100% 2px;
			background-position: 0 100%;
		}
	</style>
	<a href={itemLink} data-sveltekit-preload-data>
		<span class="underliner nav-items inline-block h-8 text-center text-lg text-white">
			{itemName}
		</span>
		{#if itemDropdown}
			<img
				src={icoMore}
				alt={itemName}
				class="-mt-1 inline-block h-6 w-6"
				style="filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(343deg) brightness(110%) contrast(100%);"
			/>
		{/if}
	</a>
</div>
