<script context="module" lang="ts">
	import logoWordmark from '$lib/img/branding/logos/active_wordmark_red.svg';
	import AppMenuItem from './DesktopMenuItem.svelte';

	import iconArrowRight from 'iconoir/icons/nav-arrow-right.svg';
</script>

<nav class="m-auto mt-8 h-16 max-w-screen-2xl">
	<div class="flex w-full justify-between">
		<div class="flex gap-x-10" role="menubar">
			<AppMenuItem itemName="Home" itemLink="/" />
			<AppMenuItem itemName="Portal" itemLink="/dash" />
			<AppMenuItem itemName="Programs" itemLink="/programs" />
			<AppMenuItem itemName="Events" itemLink="/events" />
			<AppMenuItem itemName="About Us" itemLink="/about" />
			<AppMenuItem itemName="Resources" itemLink="/more" />
		</div>
		<div
			role="menuitem"
			class="nav-items inline-block self-end rounded-full bg-gray-100 px-6 py-1.5 text-center font-normal text-gray-900"
		>
			<a href="/dash" data-sveltekit-preload-data>
				<span>Login</span>
				<img src={iconArrowRight} alt="Right Arrow Icon" class="inline h-6 w-6" style="" />
			</a>
		</div>
	</div>
</nav>
