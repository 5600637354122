<script lang="ts" context="module" sourceType="module">
	// General Components
	import Footer from '$lib/footer/Footer.svelte';

	// Style
	import '../app.css';
	import '../fonts.css';

	// Notifications
	import { SvelteToast, toast } from '@zerodevx/svelte-toast';

	// Components
	import NavBar from '$lib/components/AppMenu.svelte';
	import DesktopMenu from '$lib/components/nav/DesktopMenu.svelte';

	// Help Chat
	import {Crisp} from "crisp-sdk-web";
	import {browser} from "$app/environment";
	
	if (browser) {
	    Crisp.setHideOnMobile(true);
	    Crisp.configure("e393ac41-233a-485f-9bde-92110e8125a3");
	    Crisp.chat.onChatClosed(() => {
	        Crisp.chat.hide();
	        toast.push("Chat can be resumed from More » Support.")
	    })
	}

	const options = {
		pausable: true
	};
</script>


<div class="hidden md:block">
	<DesktopMenu />
</div>

<main>
	<slot />
</main>

<SvelteToast {options} />

<Footer />

<div id="pagePadding" class="mb-20" />

<div class="md:hidden">
	<NavBar />
</div>

<style>
	:root {
		--toastBarBackground: rgba(255, 0, 0, 0.75);
	}

	@media only screen and (min-width: 768px) {
		:root {
			--toastWidth: 32rem;
			--toastContainerTop: auto;
			--toastContainerBottom: 2rem;
		}
	}
</style>
