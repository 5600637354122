<script lang="ts">
	export let itemIcon: any = null;
	export let itemName = 'Null';
	export let itemLink = '#';
</script>

<div role="menuitem" class="w-16">
	<a href={itemLink} data-sveltekit-preload-data>
		<img
			src={itemIcon}
			alt={itemName}
			class="[filter: invert(10%) sepia(0%) saturate(0%) hue-rotate(84deg) brightness(89%) contrast(86%);] dark:[filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(275deg) brightness(106%) contrast(96%);] m-auto"
		/>
		<p class="block text-center text-sm font-semibold text-neutral-900 dark:text-neutral-50">
			{itemName}
		</p>
	</a>
</div>
