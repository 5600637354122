<script type="module" lang="ts">
	import iconFacebook from 'iconoir/icons/facebook-tag.svg';
	import iconInstagram from 'iconoir/icons/instagram.svg';
	import iconLinkedin from 'iconoir/icons/linkedin.svg';
</script>

<footer class="m-4 pt-16">
	<div class="flex justify-center gap-x-4">
		<a href="https://www.facebook.com/ActiveGymnastics/">
			<img src={iconFacebook} alt="Facebook Logo" class="h-6 w-6" />
		</a>
		<a href="https://instagram.com/activegymnastics/">
			<img src={iconInstagram} alt="Instagram Logo" class="h-6 w-6" />
		</a>
		<a href="https://www.linkedin.com/company/activegymnastics/">
			<img src={iconLinkedin} alt="Linkedin Logo" class="h-6 w-6" />
		</a>
	</div>
	<div class="mt-4 text-center">
		<a href="/policies"><p>Legal Terms, Conditions & Policies</p></a>
		<p>&copy; Active Gymnastics</p>
	</div>
</footer>
